
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'

@Options({
  components: {
    PagePlugin
  }
})
export default class complaints extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name)
  }

  table1_all_data = []

  table1_total = 0
  table1_search_data = {
    result_name: ''
  }

  rule_table1_search_data = {}

  table1_detail_dialog = {
    open: false,
    title: '详情'
  }

  table1_detail_data = {
    user_name: '',
    result_name: '',
    content: '',
    result: '',
    status: 0,
    created_at: ''
  }

  table1_edit_dialog = {
    open: false,
    title: '处理'
  }

  table1_edit_data = {
    id: 0,
    user_name: '',
    result_name: '',
    content: '',
    result: '',
    status: 0
  }

  rule_table1_edit_data = {}

  status_197 = new Map<any, string>([
    [0, '待处理'],
    [1, '已处理']
  ])

  table1_get_all_data(offset = 0, length = 10) {
    const postData = {
      offset: offset || 0,
      length: length || 10,
      result_name: this.table1_search_data.result_name
    }
    Api.http_complaintstable10(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.table1_all_data = res.data.data
        this.table1_total = res.data.total
      }
    })
  }
  table1_detail_init(row) {
    Api.http_complaintstable1get4({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.table1_detail_data.user_name = res.data.user_name
        this.table1_detail_data.result_name = res.data.result_name
        this.table1_detail_data.content = res.data.content
        this.table1_detail_data.result = res.data.result
        this.table1_detail_data.status = res.data.status
        this.table1_detail_data.created_at = res.data.created_at
        this.table1_detail_dialog.open = true
      }
    })
  }
  table1_detail_cancle() {
    this.table1_detail_dialog.open = false
  }
  table1_detail_ok() {
    this.table1_detail_dialog.open = false
  }
  table1_edit_init(row) {
    Api.http_complaintstable1get5({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.table1_edit_data.id = res.data.id
        this.table1_edit_data.user_name = res.data.user_name
        this.table1_edit_data.result_name = res.data.result_name
        this.table1_edit_data.content = res.data.content
        this.table1_edit_data.result = res.data.result
        this.table1_edit_data.status = res.data.status
        this.table1_edit_dialog.open = true
      }
    })
  }
  table1_edit_cancle() {
    this.table1_edit_dialog.open = false
  }
  table1_edit_ok() {
    this.$refs.ref_table1_edit_data.validate((valid) => {
      if (valid) {
        const postData = {
          id: this.table1_edit_data.id,
          user_name: this.table1_edit_data.user_name,
          result_name: this.table1_edit_data.result_name,
          content: this.table1_edit_data.content,
          result: this.table1_edit_data.result,
          status: this.table1_edit_data.status
        }
        Api.http_complaintstable15(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.table1_edit_dialog.open = false
            this.table1_get_all_data(0, 10)
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  table1_init() {
    this.table1_get_all_data(0, 10)
  }
  created() {
    this.table1_init()
  }
}
